<template>
  <v-container fluid>
    <div class="main">
      <v-row v-if="isLoading">
        <v-col cols="6" lg="4" v-for="item in 3" :key="item">
          <v-card flat class="rounded-lg">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg mt-2" flat>
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <v-btn
                    text
                    color="grey"
                    class="rounded-lg"
                    @click="$router.go(-1)"
                  >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Retour
                  </v-btn>

                  <h4>Chauffeurs</h4>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="4" md="6">
            <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
            >
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-account</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start align-center mt-4">
                <p class="font-weight-bold text-lg mb-1 mt-2">Fidélité</p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{ analyse.count_stable }}
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>

          <!-- Par chauffeur  -->
          <v-col cols="12" lg="4" md="6">
            <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
            >
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-percent</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start align-center mt-4">
                <p class="font-weight-bold text-lg mb-1 mt-2">
                  Consommation <br />Par chauffeur
                </p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{ CurrencyFormatting(analyse.consumed_by_driver) }} DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>

          <!-- Freauence  -->
          <v-col cols="12" lg="4" md="6">
            <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
            >
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-calendar</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start align-center mt-4">
                <p class="font-weight-bold text-lg mb-1 mt-2">Frequence</p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{ analyse.frequence }}
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-sheet :rounded="'lg'">
              <v-card-text class="align-start mt-2">
                <div>
                  <div class="d-flex justify-space-between">
                    <div></div>
                    <div>
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="refresh" v-bind="attrs" v-on="on">
                            <v-icon> mdi-refresh </v-icon>
                          </v-btn>
                        </template>
                        <span>Actualiser</span>
                      </v-tooltip>

                      <FilterDriver
                        @filter="[filterBy($event)]"
                        :filter="filter"
                        class="mr-2"
                      />
                      <v-btn
                        rounded
                        class="primary text-none"
                        depressed
                        @click="exportExcel()"
                        v-if="analyse && analyse.drivers && analyse.drivers.length > 0"
                        :loading="exportLoading"
                      >
                        <v-icon class="mr-2">mdi mdi-microsoft-excel</v-icon>
                        Exporter
                      </v-btn>
                    </div>
                  </div>

                  <v-divider class="mt-3 mb-3" />
                  <v-simple-table v-if="analyse.drivers.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="font-weight-bold text-center">
                            Téléphone
                          </th>
                          <th class="font-weight-bold text-center">Quantite</th>
                          <th class="font-weight-bold text-right">Total</th>
                          <th class="font-weight-bold text-center">
                            Transactions (Nbr Jour)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in analyse.drivers"
                          :key="item.phone"
                          @click="showDriver(item.phone)"
                          style="cursor: pointer"
                        >
                          <td class="font-weight-bold text-center">
                            {{ item.phone }}
                          </td>
                          <td class="font-weight-bold text-center">
                            {{ item.quantity }}
                          </td>
                          <td
                            class="
                              font-weight-bold
                              text-right
                              pink--text
                              darken-2
                              text-no-wrap
                            "
                          >
                            {{ CurrencyFormatting(item.total) }} DZD
                          </td>
                          <td class="font-weight-bold text-center">
                            {{ item.transaction_in_days }}
                          </td>
                        </tr>
                        <!-- <tr style="border-top: 1px solid #333">
                        <td class="font-weight-bold text-right">
                            <strong>Total :</strong>
                        </td>
                        <td class="font-weight-bold text-center">
                            {{ CurrencyFormatting(analyse.total_quantity) }}
                        </td>
                        <td class="font-weight-bold text-right">
                            {{ CurrencyFormatting(analyse.total_total) }} DZD
                        </td>

                        
                    </tr> -->
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div v-else class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import FilterDriver from "./FilterDriver.vue";
import moment from "moment";

export default {
  components: { FilterDriver },
  data() {
    return {
      exportLoading: false,
      filter: {
        startDate: moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    fetchData() {
      this.$store.dispatch("fetchAnalysesDrivers", {
        filter: this.filter,
      });
    },

    refresh() {
      this.filter = {};
      this.fetchData();
    },

    showDriver(phone) {
      this.$router.push("/analyses_vouchers/show_driver/" + phone);
    },

    exportExcel() {
      
      let baseUrl = process.env.VUE_APP_FILE_URL;
      let token = this.$store.state.user.access_token;
      let userId = this.$store.state.user.user.id;

      let url =
        baseUrl +
        "/api/vouchers/exportDrivers?token=" +
        token +
        "&user_id=" +
        userId;

      url += "&filter=" + encodeURIComponent(JSON.stringify(this.filter));

     // window.location.href = url;
      this.downloadFile(url)
    },

    downloadFile(urlToSend) {
      this.exportLoading = true;
        var req = new XMLHttpRequest();
        req.open("GET", urlToSend, true);
        req.responseType = "blob";
        req.onload = (event) => {
            var blob = req.response;
            var fileName = "Vouchers"; //if you have the fileName header available
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download=fileName;
            link.click();
            this.exportLoading = false;
        };

        req.send();
    },

    filterBy(filter) {
      this.filter = filter;
      this.fetchData();
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    analyse() {
      return this.$store.getters.getAnalysesDrivers;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>