<template>
    <v-dialog max-width="500" scrollable v-model="dialog" persistent>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              class="mr-3 ml-3"
            >
              <v-icon>mdi-tune</v-icon>
            </v-btn>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
          <v-icon left>mdi-tune</v-icon>
          Filtrage
          <v-spacer />
          
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12">
              <v-select
                class="mb-3"
                prepend-icon="mdi-filter-outline"
                multiple
                chips
                deletable-chips
                small-chips
                dense
                clearable
                :items="suppliers"
                v-model="filter.supplier_ids"
                :item-text="'name'"
                :item-value="'id'"
                label="Suppliers..."
                hide-details
              ></v-select>
  
              <v-dialog
                ref="dialogStartDate"
                v-model="modalStartDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.startDate"
                    label="Date début"
                    hide-details
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.startDate"
                  @input="[(filter.endDate = null), (modalStartDate = false)]"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12">
              <v-dialog
                ref="dialogEndDate"
                v-model="modalEndDate"
                @click="modalEndDate = false"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.endDate"
                    label="Date fin"
                    hide-details
                    :disabled="!filter.startDate"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="modalEndDate = false"
                  v-model="filter.endDate"
                  :min="filter.startDate"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="isDisabled"
            depressed
            block
            @click="handleFilter"
          >
            Recherche
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: ["filter"],
    data() {
      return {
        dialog: false,
  
        // left
        modalStartDate: false,
        modalEndDate: false,
        panel: [0],
      };
    },
  
    methods: {
      handleFilter() {
        this.dialog = false;
        this.$emit("filter", this.filter);
        //this.clearFilter();
      },
    },
    computed: {
      isDisabled() {
        return Object.values(this.filter).every(
          (x) => x === null || x === "" || x.length === 0
        );
      },
      suppliers() {
        return this.$store.getters.getSuppliers;
      },
    },
  };
  </script>
  
  